import {
	forEach,
	keys,
} from 'lodash';

import {
	ICommonBrowserList,
	ICommonBrowserScrollSizes,
} from './interfaces';


export class CommonBrowser {
	browser: ICommonBrowserList = {};
	supportsTransitions: boolean;
	scrollSizes: ICommonBrowserScrollSizes;
	isHiddenTab: boolean;
	ieVersion = null;

	constructor() {
		const html = document.querySelector('html');
		const userAgent = window.navigator.userAgent;
		const browsers = {
			ie: /Trident\/7/i,
			chrome: /chrome/i,
			safari: /safari/i,
			firefox: /firefox/i,
		};

		forEach(browsers, (value, key) => {
			this.browser[key] = browsers[key].test(userAgent);

			if (this.browser[key]) {
				html.classList.add(key);
			}
		});

		this.browser.unknown = !keys(this.browser).length;
		this.supportsTransitions = this.transitionsTest();
		this.scrollSizes = this.getScrollSizes();
		// For backward compatibility (using in old controls)
		this.ieVersion = this.checkIEVersion(userAgent);

		this.onHiddenTab(window, window.document);
	}

	protected transitionsTest(): boolean {
		const elem = document.body || document.documentElement;
		const style = elem.style;
		const vendors = ['Moz', 'webkit', 'Webkit', 'Khtml', 'O', 'ms'];

		let prop = 'transition';
		let vendor;
		let i;
		let len;

		if (typeof style[prop] === 'string') {
			return true;
		}

		prop = prop.charAt(0).toUpperCase() + prop.substr(1);

		for (i = 0, len = vendors.length; i < len; i++) {
			vendor = vendors[i];

			if (typeof style[vendor + prop] === 'string') {
				return true;
			}
		}

		return false;
	}

	protected getScrollSizes(): ICommonBrowserScrollSizes {
		const size = 100;
		const child = document.createElement('div');
		child.style.height = '100%';

		const tester = document.createElement('div');
		tester.setAttribute('class', 'b-tester-scroll_size');
		tester.setAttribute('style', `
			position: absolute;
			top: -9999px;
			left: -9999px;
			overflow: scroll;
			height: ${size}px;
			width: ${size}px;
		`);

		tester.appendChild(child);
		document.body.appendChild(tester);

		const result = {
			width: size - child.offsetWidth,
			height: size - child.offsetHeight,
		};

		document.body.removeChild(tester);
		return result;
	}

	protected onHiddenTab (window, document): void {
		const self = this;
		const defaultHiddenKey = 'hidden';
		const mozHiddenKey = 'mozHidden';
		const webkitHiddenKey = 'webkitHidden';
		const msHiddenKey = 'msHidden';
		const hidden = defaultHiddenKey in document;
		const mozHidden = mozHiddenKey in document;
		const webkitHidden = webkitHiddenKey in document;
		const msHidden = msHiddenKey in document;
		const onfocusin = 'onfocusin' in document;

		let hiddenKey = defaultHiddenKey;

		// Standards:
		if (hidden) {
			document.addEventListener('visibilitychange', onchange);
		} else if (mozHidden) {
			hiddenKey = mozHiddenKey;
			document.addEventListener('mozvisibilitychange', onchange);
		} else if (webkitHidden) {
			hiddenKey = webkitHiddenKey;
			document.addEventListener('webkitvisibilitychange', onchange);
		} else if (msHidden) {
			hiddenKey = msHiddenKey;
			document.addEventListener('msvisibilitychange', onchange);

			// IE 9 and lower:
		} else if (onfocusin) {
			document.onfocusin = document.onfocusout = onchange;

			// All others:
		} else {
			window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange;
		}

		// With zonejs
		function onchange (evt) {
			const evtMap = {
				focus: true,
				focusin: true,
				pageshow: true,
				blur: false,
				focusout: false,
				pagehide: false,
			};

			evt = evt || window.event;

			/* jshint ignore:start */
			if (evt.type in evtMap) {
				self.isHiddenTab = !(evtMap[evt.type]);
			} else {
				self.isHiddenTab = !!(this[hiddenKey]);
			}
			/* jshint ignore:end */
		}

		// set the initial state (but only if browser supports the Page Visibility API)
		if (document[hiddenKey] !== undefined) {
			onchange({ type: document[hiddenKey] ? 'blur' : 'focus' });
		}
	}

	// For backward compatibility (using in old controls)
	protected checkIEVersion(userAgent: string): number {
		let result: number = null;

		forEach({
			9: /Trident\/5/i,
			10: /Trident\/6/i,
			11: /Trident\/7/i,
		}, (regExp, key) => {
			if (regExp.test(userAgent)) {
				result = parseInt(key);
			}
		});

		return result;
	}
}

export const commonBrowserService = new CommonBrowser();
