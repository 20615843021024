import { Injectable } from '@angular/core';

@Injectable()
export class CommonHotkeyService {
	registerHotkeys(modulesHotkeyHandlers) {
		//
	}

	getComboByConfigName(name): any {
		//
	}

	replaceHotkey(config) {
		//
	}
}
