import {
	AbstractControl,
	NG_VALIDATORS,
	ValidationErrors,
	Validator,
	Validators,
} from '@angular/forms';
import {
	Directive,
	Input,
} from '@angular/core';


export const COMMON_MAX_ERROR_KEY = 'max';

@Directive({
	selector: '[commonMax]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: CommonMaxValidatorDirective,
		multi: true,
	}],
})
export class CommonMaxValidatorDirective implements Validator {
	@Input() commonMax: number;

	validate(control: AbstractControl): ValidationErrors | null {
		return Validators.max(this.commonMax)(control);
	}
}
