import { of, Observable } from 'rxjs';

import { CommonDataServiceClass } from '../data/data.service';

export interface ICommonAppData {
	appName: string,
	appFullLocationUrl: string,
	domainName: string,
	appStartNotificationText: string,  // For test notifications 'Access denied' before redirect
	LogEnabled: boolean,
	IsWebSocketsEnabled: boolean,
	IsDebugToolsEnabled: boolean,  // client-side debug tools
	IsPerformanceCheckEnabled: boolean,  // client-side performance check tools
}

export class CommonAppDataService<T = any> extends CommonDataServiceClass<T> {
	res: Observable<any>;

	constructor(
	) {
		super();

		this.res = of(window.AppData);
		this.res.subscribe((result) => this.setData(result));
	}

	init(): Observable<ICommonAppData> {
		return this.res;
	}

	wait(): Observable<ICommonAppData> {
		return this.res;
	}
}


export const commonAppDataServiceFactory = () => {
	return new CommonAppDataService();
};
