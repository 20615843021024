import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable } from 'rxjs';

import { CommonDataServiceClass } from '../data/data.service';
import { CommonBootstrapService } from './bootstrap.service';
import { CommonGeneralSettingsService } from '../general_settings/general_settings.service';
import {
	COMMON_APP_CONFIG,
	ICommonAppConfig,
} from '../app/app.config.token';

const DEFAULT_BOOTSTRAP_API_URL = 'api/bootstrap/GetBootstrap';

@Injectable({
	providedIn: 'root',
})
export class CommonBootstrapDataService extends CommonDataServiceClass<any> {
	constructor (
		private http: HttpClient,
		private commonBootstrapService: CommonBootstrapService,
		private commonGeneralSettingsService: CommonGeneralSettingsService,
		@Inject(COMMON_APP_CONFIG) protected appConfig: ICommonAppConfig,
	) {
		super();
	}

	init (shouldLoadData = false): Observable<any> {
		const { appConfig: { bootstrapApiUrl } } = this;
		let promise: Promise<any>;

		if (window.AppBootstrap || !shouldLoadData) {
			promise = Promise.resolve(window.AppBootstrap || {});

		} else if (shouldLoadData) {
			promise = this.http
				.get(`${window.location.origin}/${bootstrapApiUrl || DEFAULT_BOOTSTRAP_API_URL}`)
				.toPromise();
		}

		promise.then((result) => {
			window.AppBootstrap = result;
			this.setData(result);
			this.commonBootstrapService.init(result);
			this.commonGeneralSettingsService.init();
		});

		return from(promise);
	}
}
