// New version src/Common/user/user_data.service.ts CommonUserDataService
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/services/user_data_service_factory.js UserDataService

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Observable, forkJoin } from 'rxjs';
import { mergeMap, map, shareReplay } from 'rxjs/operators';
import { get, isNull, defaultsDeep } from 'lodash';

import { CommonDataServiceClass } from '../data/data.service';
import { CommonBootstrapDataService } from '../bootstrap/bootstrap_data.service';
import { commonConstants } from '../constants/common.constants';
import { ICommonModel, ICommonSysNameModel, UUID } from '../interfaces/core';

import { environment } from '../../../environments/environment';
import { ICommonEntityAttributesServiceEntity } from '../custom-block/services/common-entity-attributes.service/common-entity-attributes.service';
import { ITimeZone } from '../../App/src/profile/profile-page/app-profile-time-zone-dictionary.service';
import { ICommonPermissionsModel } from '@CaseOne/Common/permissions/interfaces/permissions-model.interface';


export enum COMMON_USER_TYPE {
	USER = 'User',
	CLIENT = 'Client',
}

export interface ICommonUserShort {
	Id: UUID,
	DisplayName: string,
	Email: string,
	Initials: string,
	FileAvatarId: UUID,
	Post: string,
}

export interface ICommonUser extends ICommonEntityAttributesServiceEntity, ICommonPermissionsModel {
	Email: string,
	DisplayName: string,
	FileAvatarId: UUID,
	IsAdmin: boolean,
	IsClient: boolean,
	PendingPhone: string,
	NextPhoneConfirmationAttemptTime: number,
	IsPhoneChanged: boolean
	IsPhoneConfirmationSmsSent: boolean
	IsPhoneConfirmed: boolean
	IsTwoFactorAuthByPhoneActive: boolean,
	Branch: ICommonModel,
	CompanyName: string,
	CompanyUrl: string,
	FirstName: string,
	LastName: string,
	MiddleName: string,
	Initials: string,
	LEDESTimekeeperClassification: ICommonModel,
	LEDESTimekeeperId: string,
	Phone: string,
	PhoneCode: string,
	Post: string,
	TimeZone: ITimeZone,
	UserType: ICommonSysNameModel<COMMON_USER_TYPE>,
	WorkingStatus: ICommonSysNameModel,

	// Change pass
	Old?: string,
	New?: string,
	Confirm?: string,
}

@Injectable({
	providedIn: 'root',
})
export class CommonUserDataService extends CommonDataServiceClass<ICommonUser> {
	private res: Observable<any>;

	constructor(
		private http: HttpClient,
		private commonBootstrapDataService: CommonBootstrapDataService,
	) {
		super();
	}

	init(shouldLoadData = false): Observable<any> {
		const requestOptions = {
			withCredentials: true,
			headers: new HttpHeaders({
				'Content-Type': 'application/json;charset=UTF-8',
			}),
		};

		if (window.UserData || !shouldLoadData) {
			this.res = forkJoin([
				of({ Result: window.UserData || {}}),
				this.commonBootstrapDataService.init(shouldLoadData),
			]);

		} else if (shouldLoadData) {
			this.res = this.http
				.post(
					`${window.location.origin}/authentication/account/login`,
					{
						login: environment.config.login,
						password: environment.config.password,
					},
					requestOptions,
				)
				.pipe(
					// get user profile and bootstrap only after authorization
					mergeMap(() => forkJoin([
						this.http.get(`${window.location.origin}/api/UserProfiles/Get`, requestOptions),
						this.commonBootstrapDataService.init(shouldLoadData),
					]),
				))
				.pipe(shareReplay(1));
		}

		this.res = this.res
			.pipe(
				map((result) => {
					const userData = get(result, '[0].Result');
					const userDataFromConf = environment.userData;
					return defaultsDeep(userDataFromConf, userData);
				}),
			);

		this.res.subscribe((user) => {
			window.UserData = user;
			this.setData(user);
		});

		return this.res;
	}

	wait(): Observable<any> {
		return this.res;
	}

	getUserAvatarUrl (id: string | null, mode: string): string {
		const size = commonConstants.USER_AVATAR_SIZE[mode] || commonConstants.USER_AVATAR_SIZE.standart;
		const multiplier = window.devicePixelRatio > 1 ? 2 : 1;

		return isNull(id) ? '' : `${commonConstants.downloadFilesAPIUrl}?id=${id || this.getDataByPath('FileAvatarId')}&width=${size * multiplier}&height=${size * multiplier}`;
	}

	getUserForSuggest (): ICommonModel {
		const user = this.getData();

		return {
			Id: user.Id,
			Name: user.DisplayName,
		};
	}
}
