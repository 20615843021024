import {
	AbstractControl,
	NG_VALIDATORS,
	ValidationErrors,
	Validator,
	Validators,
} from '@angular/forms';
import {
	Directive,
	Input,
} from '@angular/core';

export const COMMON_MIN_ERROR_KEY = 'min';

@Directive({
	selector: '[commonMin]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: CommonMinValidatorDirective,
		multi: true,
	}],
})
export class CommonMinValidatorDirective implements Validator {
	@Input() commonMin: number;

	validate(control: AbstractControl): ValidationErrors | null {
		return Validators.min(this.commonMin)(control);
	}
}
