import { Injectable } from '@angular/core';

declare const window;

@Injectable({
	providedIn: 'root',
})
export class CommonStatisticGoogleTagManagerService {
	sendEvent(eventName: string): void {
		if (window.dataLayer) {
			window.dataLayer.push({event: eventName});
		}
	}
}
