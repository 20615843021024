import {
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	Output,
	SimpleChanges,
	Directive,
} from '@angular/core';
import {
	uniqueId,
	isEqual,
	cloneDeep,
	isNil,
} from 'lodash';
import {
	ControlValueAccessor,
	FormControlDirective,
	FormGroupDirective,
	NgModel,
} from '@angular/forms';
import { ICommonValidationComponentCustomErrors } from './validation/validation.component';
import { COMMON_CONTROL_STATUS } from './control_status.constant';
import { CommonBaseComponent } from '../../base_component/base.component';
import { CommonFormComponent } from '../../form/common-form.component/common-form.component';
import { BehaviorSubject } from 'rxjs';

// TODO: Need rename to CommonBaseControl
@Directive()
export class CommonControlComponent<ModelType = any> extends CommonBaseComponent implements ControlValueAccessor {
	@Input() model: ModelType; // Model input for old Angular integration mode
	@Output() modelChange = new EventEmitter<ModelType>(); // Model change emitter for old Angular integration mode

	@Input() get formTabIndex(): number {
		return this.isDisabled ? null : this.tabIndex;
	}
	set formTabIndex(tabIndex) {
		this.tabIndex = tabIndex;
	}
	@Input() isDisabled: boolean = false; // Control equivalent for disabled attribute on input element, disabled control
	@Input() isReadonly: boolean = false; // Disabled the ability to edit control model
	@Input() isOldContext: boolean; // Enabled old Angular integration mode
	@Input() oldModelCtrl: any; // Controller for old Angular ngModel
	@Input() placeholder: string = ''; // Placeholder for input (null as default, because if it is null - it is not rendered in tags)
	@Input() floatLabel: string = ''; // Float label in input
	@Input() highlight: number;  // used to temporarily highlight the control

	/** @deprecated Use cssClasses from CommonBaseComponent */
	@Input() ctrlClasses: string = ''; // Css classes for control root element
	@Input() name: string = ''; // Control name for Form
	@Input() enabledValidationMessages: boolean = true;

	@Input() ignoreSubmittedForm: boolean = false;
	@Input() customErrors: ICommonValidationComponentCustomErrors; // Custom errors messages for validation

	@Output() onChange = new EventEmitter<ModelType>(); // Model change emitter, called when user changes the model
	@Output() onEnter = new EventEmitter<KeyboardEvent>();
	@Output() onKeyDown = new EventEmitter<KeyboardEvent>();
	@Output() onKeyUp = new EventEmitter<KeyboardEvent>();
	@Output() onClick = new EventEmitter<MouseEvent>();
	@Output() onFocus = new EventEmitter<Event>();
	@Output() onBlur = new EventEmitter<Event>();
	@Output() focusState = new EventEmitter<boolean>();
	@Output() onRegisterRef = new EventEmitter<CommonControlComponent<ModelType>>();

	elementRef = this.injector.get(ElementRef);
	ngModelControl: NgModel;
	formControlDirective: FormControlDirective;
	formGroupDirective: FormGroupDirective;
	id: string = '';

	/** @deprecated */
	isFocused: boolean = false;

	isFocused$ = new BehaviorSubject<boolean>(false);
	isHovered: boolean = false;
	isPressed: boolean = false;
	tabIndex: number = 0;

	isValid: boolean;
	isShowValidation: boolean = false;
	isEmpty: boolean;

	protected onChangeForValueAccessor: (value: ModelType) => {};
	protected onTouchedForValueAccessor: () => {};
	protected idKey: string = 'control_';
	protected form: CommonFormComponent;
	private previousModel: ModelType;  // saved prev model input for old Angular integration mode

	// highlight props start
	isBgTransitionEnabled$ = new BehaviorSubject<boolean>(false);  // enable background-color transition
	isHighlighted$ = new BehaviorSubject<boolean>(false);  // enable highlighted background-color

	private bgTransitionTimer: any;
	private highlightTimer: any;
	private readonly HIGHLIGHT_IN_TIME = 300;
	private readonly HIGHLIGHT_DELAY_TIME = 1000;
	private readonly HIGHLIGHT_OUT_TIME = 3000;
	// highlight props end

	@HostBinding('attr.qa-control-name') get qaControlName() {
		return this.name;
	}
	set qaControlName(value) {
		// do nothing
	}

	writeValue(value: ModelType): void {
		this.model = value;

		this.onModelChange();
		this.runUpdate();
	}

	registerOnChange(fn: any): void {
		this.onChangeForValueAccessor = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedForValueAccessor = fn;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.enabledValidationMessages) {
			this.recalcShowValidation();
		}

		if (changes.highlight && !isNil(changes.highlight.currentValue) && changes.highlight.currentValue !== changes.highlight.previousValue) {
			this.startHighlight();
		}
	}

	ngOnInit() {
		super.ngOnInit();

		this.initNgModelControl();
		this.registerControlInForm();
		this.id = uniqueId(this.idKey);

		if (this.ngModelControl || this.formGroupDirective) {
			this.subscribe((this.ngModelControl || this.formGroupDirective).formDirective.ngSubmit, () => {
				this.onNgModelControlChange();
			});

			this.subscribe((this.ngModelControl || this.formGroupDirective).control.statusChanges, () => {
				this.onNgModelControlChange();
			});
		}

		if (this.oldModelCtrl) {
			this.oldModelCtrl._ctrl_cache_$valid = this.oldModelCtrl.$valid;

			Object.defineProperty(this.oldModelCtrl, '$valid', {
				get: () => this.oldModelCtrl._ctrl_cache_$valid,
				set: (value) => {
					this.oldModelCtrl._ctrl_cache_$valid = value;
					this.onNgModelControlChange();
				},
			});

			this.oldModelCtrl._ctrl_cache_$submitted = this.oldModelCtrl.$submitted;

			Object.defineProperty(this.oldModelCtrl.$$parentForm, '$submitted', {
				get: () => this.oldModelCtrl._ctrl_cache_$submitted,
				set: (value) => {
					this.oldModelCtrl._ctrl_cache_$submitted = value;
					this.onNgModelControlChange();
				},
			});
		}

		this.recalcShowValidation();
		this.onRegisterRef.emit(this as any);
	}

	ngOnDestroy() {
		this.clearBgTransitionTimer();
		this.clearHighlightTimer();

		super.ngOnDestroy();
	}

	private startHighlight () {
		if (this.isBgTransitionEnabled$.getValue()) {
			this.clearBgTransitionTimer();
			this.clearHighlightTimer();
		} else {
			this.isBgTransitionEnabled$.next(true);
			this.isHighlighted$.next(true);
		}

		this.highlightTimer = setTimeout(() => {
			this.isHighlighted$.next(false);
			this.runUpdate();
			this.clearHighlightTimer();
		}, this.HIGHLIGHT_IN_TIME + this.HIGHLIGHT_DELAY_TIME);

		this.bgTransitionTimer = setTimeout(() => {
			this.isBgTransitionEnabled$.next(false);
			this.runUpdate();
			this.clearBgTransitionTimer();
		}, this.HIGHLIGHT_IN_TIME + this.HIGHLIGHT_DELAY_TIME + this.HIGHLIGHT_OUT_TIME);
	}

	private clearBgTransitionTimer () {
		if (this.bgTransitionTimer) {
			clearTimeout(this.bgTransitionTimer);
			this.bgTransitionTimer = null;
		}
	}

	private clearHighlightTimer () {
		if (this.highlightTimer) {
			clearTimeout(this.highlightTimer);
			this.highlightTimer = null;
		}
	}

	// old Angular doesn't call writeValue
	ngDoCheck() {
		if (this.isOldContext) {
			if (!isEqual(this.previousModel, this.model)) {
				this.writeValue(this.model);
				this.previousModel = cloneDeep(this.model);
			}
		}
	}

	isSubmittedForm(): boolean {
		if (this.isOldContext) {
			return this.oldModelCtrl.$$parentForm.$submitted;

		} else {
			if (this.ngModelControl || this.formGroupDirective) {
				return (this.ngModelControl || this.formGroupDirective).formDirective.submitted;
			} else {
				return false;
			}
		}
	}

	// With zonejs
	onMouseEnterHandler(event?: Event): void {
		this.zone.run(() => {
			this.isHovered = true;
		});
	}

	// With zonejs
	onMouseLeaveHandler(event?: Event): void {
		this.zone.run(() => {
			this.isHovered = false;
			this.isPressed = false;
		});
	}

	onFocusHandler(event: Event): void {
		if (this.isDisabled) {
			// blocked native focus
			event.preventDefault();

		} else {
			this.isFocused = true;
			this.isFocused$.next(true);
			this.focusState.emit(true);
			this.onFocus.emit(event);
		}
	}

	// With zonejs
	onBlurHandler(event?: Event): void {
		if (this.onTouchedForValueAccessor) {
			this.onTouchedForValueAccessor();
		}

		if (this.isFocused) {
			this.isFocused = false;
			this.isFocused$.next(false);
			this.focusState.emit(false);
			this.onBlur.emit(event);
		}
	}

	onMouseDownHandler(event?: MouseEvent): void {
		this.isPressed = true;
	}

	onMouseUpHandler(event?: MouseEvent): void {
		this.isPressed = false;
	}

	protected initNgModelControl() {
		// Branching to work surrounded by the old Angular
		if (this.isOldContext) {
			// Attempt to find control model
			try {
				this.ngModelControl = this.injector.get(NgModel);
			} catch (e) {
				// Empty
			}

			this.onModelChange();
		} else {
			try {
				this.ngModelControl = this.injector.get(NgModel);
			} catch (e) {
				// Not empty
			}

			if (!this.ngModelControl) {
				this.formControlDirective = this.injector.get(FormControlDirective);
				this.formGroupDirective = this.injector.get(FormGroupDirective);
			}
		}
	}

	protected registerControlInForm() {
		if (!this.isOldContext) {
			this.form = this.injector.get(CommonFormComponent, null);

			if (this.form) {
				this.form.registerControl(this as CommonControlComponent);

				this.willUnbind(() => {
					this.form.removeControl(this as CommonControlComponent);
				});
			}
		}
	}

	protected getIsValid() {
		if (this.isOldContext) {
			if (this.oldModelCtrl) {
				const validValue = this.oldModelCtrl.$valid;

				if (this.ignoreSubmittedForm) {
					return validValue;
				} else {
					const pristineForm = !this.isSubmittedForm();
					return pristineForm || validValue;
				}
			} else {
				return true;
			}
		} else {
			if (this.ngModelControl || this.formControlDirective) {
				const isPendingAsyncValidation = (this.ngModelControl || this.formControlDirective).control.status === COMMON_CONTROL_STATUS.PENDING;
				const controlIsDisabled = (this.ngModelControl || this.formControlDirective).control.status === COMMON_CONTROL_STATUS.DISABLED;
				const validValue = (this.ngModelControl || this.formControlDirective).valid;

				if (this.ignoreSubmittedForm) {
					return isPendingAsyncValidation || validValue || controlIsDisabled;
				} else {
					const pristineForm = !this.isSubmittedForm();
					return pristineForm || isPendingAsyncValidation || validValue || controlIsDisabled;
				}

			} else {
				return true;
			}
		}
	}

	protected getIsEmpty() {
		return !this.hasValue(this.model);
	}

	protected emitOnChange(value: ModelType) {
		if (this.onChangeForValueAccessor) {
			this.onChangeForValueAccessor(value);
		}

		this.onChange.emit(value);
	}

	protected checkIsValid(): void {
		this.isValid = this.getIsValid();
	}

	protected onModelChange(): void {
		this.isEmpty = this.getIsEmpty();
		this.checkIsValid();
	}

	protected onNgModelControlChange(): void {
		this.checkIsValid();
		this.runUpdate();
	}

	private recalcShowValidation() {
		this.isShowValidation = this.enabledValidationMessages;
	}
}
