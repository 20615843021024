// New version src/Common/url_filters/filters_from_url.service.ts
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/services/filter_from_utl_service.js

import { Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { LocationServices } from '@uirouter/core';

import {
	set,
	isArray,
	uniq,
	forOwn,
} from 'lodash';
import { CommonServerDateService } from '../server_date/server_date.service';

@Injectable()
export class CommonFiltersFromUrlService {
	static parseVal(item) {
		switch (item) {
			case 'true':
				return true;
			case 'false':
				return false;
			case 'null':
				return null;
			default:
				return item;
		}
	}

	private locationService: LocationServices;

	constructor(
		private commonServerDateService: CommonServerDateService,
		private uiRouter: UIRouter,
	) {
		this.locationService = this.uiRouter.locationService;
	}

	public get(filterNames): any {
		if (filterNames) {
			const result = {};

			forOwn(this.locationService.search(), (value, key) => {
				for (let i2 = 0, len = filterNames.length; i2 < len; i2++) {
					const filter = filterNames[i2];

					if (key.indexOf(filter.key) === 0) {
						if (filter.isArray && !isArray(value)) {
							value = [value];
						}

						if (isArray(value)) {
							for (let i = 0, j2 = 0, len1 = value.length; j2 < len1; i = ++j2) {
								const item = value[i];

								if (typeof item === 'string') {
									value[i] = CommonFiltersFromUrlService.parseVal(item);
								}
							}
							value = uniq(value);
						}

						if (filter.isObject) {
							value = JSON.parse(decodeURI(value));
						}

						if (filter.isDate) {
							if (!this.commonServerDateService.isDateString(value)) {
								continue;
							}
						}

						if (filter.isBoolean) {
							value = CommonFiltersFromUrlService.parseVal(value);

							if (!value) {
								value = false;
							}
						}

						if (typeof value === 'string') {
							value = CommonFiltersFromUrlService.parseVal(value);
							value = decodeURIComponent(value);
						}

						set(result, key, value);
					}
				}
			});

			return result;
		}
	}
}
