import { Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { forkJoin, Observable } from 'rxjs';
import { forEach, get, isEqual } from 'lodash';

import { UUID } from '../../interfaces/core';
import {
	CommonUserDataService,
	ICommonUser,
} from '../../user/user_data.service';
import { CommonAppDataService } from '../../app_data/app_data.service';
import { COMMON_STATISTICS_SERVICES } from '../constants/common-statistics-services.constant';
import { COMMON_STATISTICS_SERVICE_LOCATION } from '../constants/common-statistics-service-location.enum';


@Injectable({
	providedIn: 'root',
})
export class CommonStatisticsService {
	private readonly res: Observable<any>;
	private windowAsAny: any;
	private userData: ICommonUser;
	private appData: any;
	private hasAmplitude: boolean;
	private hasIntercom: boolean;

	constructor (
		private uiRouter: UIRouter,
		private commonUserDataService: CommonUserDataService,
		private commonAppDataService: CommonAppDataService,
	) {
		this.res = forkJoin([
			this.commonUserDataService.wait(),
			this.commonAppDataService.wait(),
		]);

		this.res.subscribe(() => {
			this.windowAsAny = window as any;
			this.userData = this.commonUserDataService.getData();
			this.appData = this.commonAppDataService.getData();
			this.hasAmplitude = !!this.windowAsAny.amplitude;
			this.hasIntercom = !!this.appData.IntercomId && !!this.windowAsAny.Intercom;

			this.uiRouter.transitionService.onEnter({}, (transition) => {
				const toState = transition.to();
				const eventName = get(toState, 'data.trackEvent.name', null);
				const eventData = get(toState, 'data.trackEvent.data', null);

				if (eventName) {
					this.trackEvent(eventName, eventData);
				}
			});

			this.initServices();
		});
	}

	public wait(): Observable<any> {
		return this.res;
	}

	public isServiceConnected(serviceName: string): boolean {
		switch (serviceName) {
			case COMMON_STATISTICS_SERVICES.AMPLITUDE:
				return this.hasAmplitude;

			case COMMON_STATISTICS_SERVICES.INTERCOM:
				return this.hasIntercom;

			default:
				console.error(`CommonStatisticsService#isServiceConnected Not found case for serviceName: '${serviceName}'`);
				return false;
		}
	}

	public getContactId(id: UUID = this.userData.Id): string {
		// Why Id + domainName – https://jira.parcsis.org/browse/CASEM-25979
		return `${id}-${this.appData.domainName}`;
	}

	public trackEvent(name: string, data?: object) {
		const windowAsAny = this.windowAsAny;

		if (this.isServiceConnected(COMMON_STATISTICS_SERVICES.AMPLITUDE)) {
			try {
				windowAsAny.amplitude
					.getInstance()
					.logEvent(name, data);
			} catch (e) {
				// Empty
			}
		}

		if (this.isServiceConnected(COMMON_STATISTICS_SERVICES.INTERCOM)) {
			try {
				windowAsAny.Intercom('trackEvent', name, data);

				// Without zonejs
				setTimeout(() => {
					windowAsAny.Intercom('update');
				}, 500);
			} catch (e) {
				// Empty
			}
		}
	}

	public getLocation(): COMMON_STATISTICS_SERVICE_LOCATION {
		const isProjectPage = this.uiRouter.stateService.includes('index.projects_view');

		return isProjectPage ? COMMON_STATISTICS_SERVICE_LOCATION.CASE_CARD : COMMON_STATISTICS_SERVICE_LOCATION.MAIN_MENU;
	}

	public checkFilter(eventName: string, newFilterValue: any, oldFilterValue: any) {
		forEach(newFilterValue, (item, key) => {
			if (!isEqual(newFilterValue[key], oldFilterValue[key])) {
				this.trackEvent(eventName, {
					'Filter name': key,
					'Location': this.getLocation(),
				});
			}
		});
	}

	private initServices() {
		const windowAsAny = this.windowAsAny;
		const AppData = this.appData;
		const userName = this.userData.DisplayName;
		const userEmail = this.userData.Email;

		if (this.isServiceConnected(COMMON_STATISTICS_SERVICES.AMPLITUDE)) {
			try {
				windowAsAny.amplitude
					.getInstance()
					.setUserProperties({
						name: userName,
						email: userEmail,
						user_id: this.getContactId(),
						account: AppData.domainName,
					});
			} catch (e) {
				// Empty
			}
		}

		if (this.isServiceConnected(COMMON_STATISTICS_SERVICES.INTERCOM)) {
			try {
				windowAsAny.Intercom('boot', {
					app_id: AppData.IntercomId,
					name: userName,
					email: userEmail,
					user_id: this.getContactId(),
					created_at: (new Date('2015-07-07')).getTime(),
					cp_SignedUp: AppData.userSignedUp,
					cp_UserType: AppData.userType,
					cp_License: AppData.userLicense,
				});
			} catch (e) {
				// Empty
			}
		}
	}
}
