import { Observable, BehaviorSubject } from 'rxjs';
import { get, clone } from 'lodash';

declare interface ICommonDataService<T> {
	data$: BehaviorSubject<T>,
	init? (): Observable<any>,
	setData (data: T): void,
	getData (): T,
	setDataByPath (data, key: string): void,
	getDataByPath (path: string): any,
}

export abstract class CommonDataServiceClass<T = any> implements ICommonDataService<T> {
	data$ = new BehaviorSubject(null);

	private data: T;

	setData (data: T): void {
		this.data = clone(data);
		this.data$.next(this.data);
	}

	getData (): T {
		return clone(this.data);
	}

	setDataByPath (data, key: string): void {
		this.data[key] = clone(data);
	}

	getDataByPath (path: string): any {
		return get(this.data, path);
	}
}
