import { NgModule } from '@angular/core';

import { CommonStatisticsService } from './services/common-statistics.service';
import { CommonStatisticGoogleTagManagerService } from './services/common-google-tag-manager.service';
import { CommonModule } from '@angular/common';

@NgModule({
	providers: [
		CommonModule,
		CommonStatisticsService,
		CommonStatisticGoogleTagManagerService,
	],
})
export class CommonServicesStatisticsModule {
}
