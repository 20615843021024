import { CommonEscapedBracketReplace } from './escape_bracket_replace.filter';
import { NgModule } from '@angular/core';

import { CommonHotkeyModule } from '../hotkey/hotkey.module';

import { CommonSecondsFormatterPipe } from './seconds_formatter.pipe';
import { CommonTwoDigitsNumberPipe } from './two_digits_number.pipe';
import { CommonSafeHtmlPipe } from './safe-html.pipe';
import { CommonSortByPipe } from './sort-by.pipe';
import { CommonTitlePipe } from './common-title.pipe';
import { CommonDatePipe } from './common-date.pipe';
import { CommonNAFormatPipe } from './common-na-format.pipe';
import { CommonUtilitiesCore } from '../utilities/core.service';
import { CommonPrettyNumberPipe } from './common-pretty-number.pipe';
import { CommonRemoveHtmlTagsPipe } from './common-remove-html-tags.pipe';
import { CommonNumberFormatterPipe } from './common-number-formatter.pipe';
import { CommonNumberWithCurrencyFormatterPipe } from './common-number-with-currency-formatter.pipe';
import { CommonListFormatterPipe } from './common-list-formatter.pipe';
import { CommonRecipientsListFormatterPipe } from './common-recipients-list-formatter.pipe';
import { CommonLineToBrPipe } from './common-line-to-br.pipe';
import { CommonListItemsFilterPipe } from './common-list-items-filter.pipe';
import { CommonHotkeyInfoPipe } from './common-hotkey-info.pipe';
import { CommonSafeUrlPipe } from '@CaseOne/Common/pipes/safe-url.pipe';
import { CommonPercentFormatterPipe } from './common-percent-formatter.pipe';
import { CommonSwitchSignOfNumberFormatterPipe } from './common-switch-sign-of-number-formatter.pipe';
import { CommonHtmlStripPipe } from './common-html-strip.pipe';
import { CommonRouteNamePipe } from './common-route-name.pipe';

@NgModule({
	declarations: [
		CommonSecondsFormatterPipe,
		CommonTwoDigitsNumberPipe,
		CommonEscapedBracketReplace,
		CommonSafeHtmlPipe,
		CommonSafeUrlPipe,
		CommonSortByPipe,
		CommonTitlePipe,
		CommonDatePipe,
		CommonNAFormatPipe,
		CommonPrettyNumberPipe,
		CommonRemoveHtmlTagsPipe,
		CommonNumberFormatterPipe,
		CommonNumberWithCurrencyFormatterPipe,
		CommonListFormatterPipe,
		CommonRecipientsListFormatterPipe,
		CommonLineToBrPipe,
		CommonListItemsFilterPipe,
		CommonHotkeyInfoPipe,
		CommonPercentFormatterPipe,
		CommonSwitchSignOfNumberFormatterPipe,
		CommonHtmlStripPipe,
		CommonRouteNamePipe,
	],
	exports: [
		CommonSecondsFormatterPipe,
		CommonTwoDigitsNumberPipe,
		CommonEscapedBracketReplace,
		CommonSafeHtmlPipe,
		CommonSafeUrlPipe,
		CommonSortByPipe,
		CommonTitlePipe,
		CommonDatePipe,
		CommonNAFormatPipe,
		CommonPrettyNumberPipe,
		CommonRemoveHtmlTagsPipe,
		CommonNumberFormatterPipe,
		CommonNumberWithCurrencyFormatterPipe,
		CommonListFormatterPipe,
		CommonRecipientsListFormatterPipe,
		CommonLineToBrPipe,
		CommonListItemsFilterPipe,
		CommonHotkeyInfoPipe,
		CommonPercentFormatterPipe,
		CommonSwitchSignOfNumberFormatterPipe,
		CommonHtmlStripPipe,
		CommonRouteNamePipe,
	],
	providers: [
		CommonSecondsFormatterPipe,
		CommonTwoDigitsNumberPipe,
		CommonEscapedBracketReplace,
		CommonDatePipe,
		CommonUtilitiesCore,
		CommonNAFormatPipe,
		CommonPrettyNumberPipe,
		CommonNumberFormatterPipe,
		CommonTitlePipe,
		CommonRemoveHtmlTagsPipe,
		CommonNumberWithCurrencyFormatterPipe,
		CommonListFormatterPipe,
		CommonRecipientsListFormatterPipe,
		CommonLineToBrPipe,
		CommonListItemsFilterPipe,
		CommonHotkeyInfoPipe,
		CommonPercentFormatterPipe,
		CommonSwitchSignOfNumberFormatterPipe,
	],
	imports: [
		CommonHotkeyModule,
	],
})
export class CommonPipesModule {}
