// New version src/Common/pipes/common-pretty-number.pipe.ts
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/filters/pretty_number_filter.js
import { Pipe, PipeTransform } from '@angular/core';
import { CommonGeneralSettingsService } from '../general_settings/general_settings.service';
import { COMMON_DEFAULT_NUMBER_CONSTANTS } from '../constants/common-default-number.constants';

@Pipe({
	name: 'commonPrettyNumber',
})
export class CommonPrettyNumberPipe implements PipeTransform {
	constructor (
		private commonGeneralSettingsService: CommonGeneralSettingsService,
	) {}

	transform(num, options?: {
		fixAfterDecimal?: boolean,  // cut off to true by default is necessary numbers of signs after a comma
	}) {
		if (!num) {
			return num;
		}

		options = options || {};
		options.fixAfterDecimal = options.fixAfterDecimal !== false;

		const decimalSeparator = this.commonGeneralSettingsService.getGeneralSettings('NumberFormat.DecimalSeparator') || COMMON_DEFAULT_NUMBER_CONSTANTS.DECIMAL_SEPARATOR;
		const groupSeparator = this.commonGeneralSettingsService.getGeneralSettings('NumberFormat.GroupSeparator') || COMMON_DEFAULT_NUMBER_CONSTANTS.GROUP_SEPARATOR;
		let result = num.toString().replace(/[' ']/g, '').replace('.', decimalSeparator).replace(/\B(?=(\d{3})+(?!\d))/g, groupSeparator);

		if (options.fixAfterDecimal) {
			const decimalIndex = result.indexOf(decimalSeparator);

			if (decimalIndex !== -1) {
				result = result.slice(0, decimalIndex + COMMON_DEFAULT_NUMBER_CONSTANTS.DIGITS_AFTER_DECIMAL + 1);
			}
		}

		return result;
	}
}
